import { JsonValue, Provider, ResolutionDetails } from '@openfeature/react-sdk';
import {env} from '../env'
/**
 * Environment Variable Provider
 * Loads & stores environment variables, defines
 * accessor methods to retrieve and evaluate feature flags at runtime
 */
export class EnvVarProvider implements Provider {
  // metadata field for Provider
  metadata: { name: string };
  readonly runsOn: 'client';
  // loaded & stored feature flags
  readonly flags: {};

  constructor() {
    this.metadata = { name: 'Environment Variable Provider' };
    this.runsOn = 'client';
    this.flags = {}
    // Load feature flags from environment variables in .env
    // An environment variable named "REACT_APP_FLAG_<NAME>" is recognized as a feature flag
    // with name "FLAG_<NAME>"
    // for (const key in process.env) {
    for (const key in env) {
      const value = env[key];
      console.log(`${key}: ${value}`);
      if (key.startsWith("REACT_APP_FLAG")) {
        const fname = key.slice("REACT_APP_".length)
        // console.log(`${key}: ${value}`);
        this.flags[fname] = value
      }
    }
  }

  /**
   * Evaluate feature flags of boolean type
   * @param flagKey - the name of the feature flag
   * @param defaultValue - default value to set flag to if not specifie
   * @return flag's value if it is specified.
   * Otherwise, if default value provided, set flag to the default value and return it
   * If flag doesn't exist and default value unspecified, return undefined.
   * */ 
  resolveBooleanEvaluation(flagKey: string, defaultValue: boolean): ResolutionDetails<boolean> {
    const value: boolean = this.flags[flagKey] !== undefined ? this.flags[flagKey] === 'true' : defaultValue;
    return {
      value: value,
    }
  }

  /**
   * Evaluate feature flags of string type
   * @param flagKey - the name of the feature flag
   * @param defaultValue - default value to set flag to if not specified
   * @return flag's value if it is specified.
   * Otherwise, if default value provided, set flag to the default value and return it
   * If flag doesn't exist and default value unspecified, return undefined.
   * */ 
  resolveStringEvaluation(flagKey: string, defaultValue: string): ResolutionDetails<string> {
    // code to evaluate string
    const value: string = this.flags[flagKey] !== undefined ? this.flags[flagKey] : defaultValue;
    return {
        value
    }
  }

  /**
   * Evaluate feature flags of number type
   * @param flagKey - the name of the feature flag
   * @param defaultValue - default value to set flag to if not specified
   * @return flag's value if it is specified.
   * Otherwise, if default value provided, set flag to the default value and return it
   * If flag doesn't exist and default value unspecified, return undefined.
   * */ 
  resolveNumberEvaluation(flagKey: string, defaultValue: number): ResolutionDetails<number> {
    // code to evaluate number
    const value: number = this.flags[flagKey] !== undefined ? this.flags[flagKey] : defaultValue;
    return {
        value
    }
  }
  
  /**
   * Evaluate feature flags of Object type
   * @param flagKey - the name of the feature flag
   * @param defaultValue - default value to set flag to if not specified
   * @return flag's value if it is specified.
   * Otherwise, if default value provided, set flag to the default value and return it
   * If flag doesn't exist and default value unspecified, return undefined.
   * */ 
  resolveObjectEvaluation<U extends JsonValue>(flagKey: string, defaultValue: U): ResolutionDetails<U> {
    // code to evaluate object
    const value: U = this.flags[flagKey] !== undefined ? this.flags[flagKey] : defaultValue;
    return {
        value
    }
  }
}
